import React from "react";
import MyDistribuitionComponent from "../../../components/myDistribuitionComponent";
import MyEarningsComponent from "../../../components/myEarningsComponent";
function TotalDashboard(props){
    return(
        <div>
            <MyDistribuitionComponent ativos={props.ativos}/>
            <br/>
            <MyEarningsComponent earnings={props.earnings}/>
        </div>
    )
}

export default TotalDashboard;