import React, {useContext, useEffect} from 'react';
import {MainContext} from '../contexts/MainContext'
import {useHistory} from 'react-router-dom';

function RedirectComponent(props) {
    const {mainData, jwt} = useContext(MainContext);
    const history = useHistory();

    useEffect(() => {
        console.log(jwt);
        if(!jwt){
            history.push('/home')
        }else{
            history.push('/dashboard')
        }
    }, [])
    return (
        <div>

        </div>
    )
}

export default RedirectComponent