import React from "react";
import useLanguage from "../hooks/useLanguageHook";

function MyDistribuitionComponent(props){
    const {languageData} = useLanguage();
    const ativos = props.ativos;
    return (
        <div className="card">
            <header className="card-header">
                <p className="card-header-title">
                    {languageData.dashboard.myDistribution}
                </p>
            </header>
            <div className="card-content">
                <div className="content">
                    <table className="table is-fullwidth is-striped">
                        <tbody>
                        {ativos.map((ativo, key) => {
                            if (ativo.stockType !== 'Total') {
                                return (
                                    <tr key={key}>
                                        <td width="5%"/>
                                        <td>{ativo.stockType}</td>
                                        <td className="level-right"><a
                                            className="button is-small is-primary">{ativo.percentage}</a>
                                        </td>
                                    </tr>
                                )
                            }
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default MyDistribuitionComponent