import React, {useState, useEffect, useContext} from "react";
import {MainContext} from "../contexts/MainContext";
import brData from '../translations/br';
import usData from '../translations/en';

function useLanguage() {
    const {LANGUAGE, changeLanguage} = useContext(MainContext);
    const [languageData, changeLanguageData] = useState(brData);

    useEffect(() => {
        switchLanguage();
    }, []);

    useEffect(() => {
        switchLanguage();
    }, [LANGUAGE]);

    function switchLanguage() {
        switch (LANGUAGE) {
            case 'pt-BR':
                changeLanguageData(brData);
                break;
            case 'en-US':
                changeLanguageData(usData);
                break;
            default:
                changeLanguageData(usData);
                break;
        }
    }

    return {languageData, changeLanguageData}
}

export default useLanguage