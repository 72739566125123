import React from "react";
import useLanguage from "../../../hooks/useLanguageHook";


function FiiListDetailsComponent(props) {
    const data = props.data;
    const earningsPerShare = new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(data.value.earningsPerShare)
    const {languageData} = useLanguage();

    let className = `button is-small `;

    if(data.value.dividendYield < 0.3){
        className += `is-danger`;
    }else if(data.value.dividendYield < 0.5){
        className += `is-warning`;
    }else{
        className += `is-primary`
    }
    if (data.value) {
        return (
            <tr >
                <td width="5%"/>
                <td>{data.value.stockId} <span className="td-small-title">{earningsPerShare}</span> <span className="td-small-subtitle">{languageData.dashboard.perQuantity}</span> </td>
                <td className="level-right"><a
                    className={className}>DY: {data.value.dividendYield}%</a>
                </td>
            </tr>
        )
    } else {
        return (
            <div key="-1">

            </div>
        )
    }
}

export default FiiListDetailsComponent