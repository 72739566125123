import React, {useContext, useEffect, useState} from "react";
import CurrencyInput from "react-currency-input";
import useHttpHook from "../hooks/useHttpHook";
import {MainContext} from "../contexts/MainContext";
import FieldInputComponent from "./shared/fieldInputComponent";
import {toast} from 'react-toastify';
import * as moment from 'moment';

function AdicionarNegociacaoAtivoComponent() {
    const {httpRequest} = useHttpHook();
    const {userData, LANGUAGE, userStocks} = useContext(MainContext);
    const [componentLoaded, changeComponentLoaded] = useState(false);

    const [isLoaded, switchLoadade] = useState(false);

    const [ativo, setAtivo] = useState('');
    // const [dataNegocio, setDataNegocio] = useState('');
    const [compraVenda, setCompraVenda] = useState('');
    const [quantidade, setQuantidade] = useState('');
    const [preco, setPreco] = useState('');
    const [corretagem, setCorretagem] = useState('');
    const [valorTotal, setValorTotal] = useState('');
    const [corretora, setCorretora] = useState('');
    const [mercado, setMercado] = useState('');
    const [especificacao, setEspecificacao] = useState('');
    const [fatorCotacao, setFatorCotacao] = useState('1');
    const [updatedMessage, setUpdatedMessage] = useState('');
    const [showForm, setToggleForm] = useState(true);
    const [dataNegocio, setDataNegocio] = React.useState(moment().format('DD/MM/YYYY'));
    const [maskString, setMaskString] = React.useState('DD/MM/YYYY');
    const [parsedDataNegocio, setParsedDataNegocio] = useState('')
    useEffect(() => {
        changeComponentLoaded(true);
    }, []);


    useEffect(() => {
        if (componentLoaded && userData && userData.userId) {

        }
    }, [userData, componentLoaded, LANGUAGE]);

    const handleFocus = (event) => event.target.select();

    function handleSubmitBudget(event) {
        event.preventDefault();
    }

    function handleChange(event) {
        setUpdatedMessage('');
        switch (event.target.name) {
            case 'ativo':
                event.target.value = event.target.value.toUpperCase();
                setAtivo(event.target.value);
                // toggleDisabled();
                break;
            case 'dataNegocio':
                setDataNegocio(event.target.value);
                // toggleDisabled();
                break;
            case 'compraVenda':
                if (event.target.value.toUpperCase() === 'V' || event.target.value.toUpperCase() === 'C' || event.target.value === '') {
                    setCompraVenda(event.target.value.toUpperCase());
                }
                // toggleDisabled();
                break;
            case 'quantidade':
                setQuantidade(event.target.value);
                // toggleDisabled();
                break;
            case 'preco':
                setPreco(event.target.value);
                // toggleDisabled();
                break;
            case 'corretagem':
                setCorretagem(event.target.value);
                // toggleDisabled();
                break;
            case 'valorTotal':
                setValorTotal(event.target.value);
                // toggleDisabled();
                break;
            case 'corretora':
                setCorretora(event.target.value);
                // toggleDisabled();
                break;
            case 'mercado':
                setMercado(event.target.value);
                // toggleDisabled();
                break;
            case 'especificacao':
                setEspecificacao(event.target.value);
                // toggleDisabled();
                break;
            case 'fatorCotacao':
                setFatorCotacao(event.target.value);
                // toggleDisabled();
                break;
            default:
                break;
        }
    }

    const onChange = e => {
        if (parseInt(e.target.value[6], 10) > 2) {
            setMaskString('DD/MM/YY');
            setDataNegocio('00/00/00');
        } else {
            setMaskString('DD/MM/YYYY');
            setDataNegocio('00/00/0000');
        }
    };

    function parseBrlAmountToBackend(value) {
        let parsedAmount = value.replace('R$', '');
        parsedAmount = parsedAmount.replace('.', '');
        parsedAmount = parsedAmount.replace('.', '');
        parsedAmount = parsedAmount.replace('.', '');
        parsedAmount = parsedAmount.replace('.', '');
        parsedAmount = parsedAmount.replace('.', '');
        parsedAmount = parsedAmount.replace(',', '.');
        return parsedAmount;
    }

    async function addNegociacaoAtivo(event) {
        event.preventDefault();
        try {
            await httpRequest('POST', `/users/${userData.userId}/assets-trading`, {
                corretoraId: corretora,
                dataDoNegocio: parsedDataNegocio.value,
                compraVenda: compraVenda,
                mercado: mercado,
                corretagem: parseBrlAmountToBackend(corretagem),
                stockId: ativo.toUpperCase(),
                especificacaoAtivo: especificacao,
                quantidade: quantidade,
                preco: parseBrlAmountToBackend(preco),
                valorTotal: parseBrlAmountToBackend(valorTotal),
                fatorCotacao: fatorCotacao
            });
            setAtivo('');
            setCompraVenda('');
            setQuantidade('');
            setPreco('');
            setCorretagem('');
            setValorTotal('');
            setCorretora('');
            setMercado('');
            setEspecificacao('');
            setFatorCotacao('1');
            setUpdatedMessage('');
            setDataNegocio(moment().format('DD/MM/YYYY'));
            toast.success(`Negociação de ativo adicionada com sucesso`);

        } catch (err) {
            console.log(err);
        }
    }


    return (
        <section className="">

            <div className="">
                <article className="tile is-child box">
                    <h3 className="subtitle" style={{'cursor': 'pointer'}} onClick={() => {
                        setToggleForm(!showForm);
                    }}>
                        <p className="adicionar-negociacao">Adicionar/incrementar ativo {showForm ?
                            <i className={'fas fa-angle-up'}/> : <i className={'fas fa-angle-down'}/>}</p>

                    </h3>
                    <br/>
                    {showForm ? <form onSubmit={handleSubmitBudget}>
                            <div className="field is-horizontal">
                                <div className="field-body">
                                    <div className="column">
                                        <FieldInputComponent
                                            isDate={true}
                                            onChange={onChange}
                                            setOnValueChange={setParsedDataNegocio}
                                            maskString={maskString}
                                            mask={dataNegocio}
                                            inputValue={dataNegocio}
                                            name={"Data"}
                                            inputType={'text'}
                                            inputName={'dataNegocio'}
                                            handleFocus={handleFocus}
                                            placeholder={'Data Negócio'}
                                            className={'fas fa-calendar-alt'}
                                        />


                                        <FieldInputComponent
                                            name={"Ativo"}
                                            inputValue={ativo}
                                            inputType={'text'}
                                            inputName={'ativo'}
                                            handleFocus={handleFocus}
                                            handleChange={handleChange}
                                            placeholder={'Ativo'}
                                            className={'fas fa-money-bill-alt'}
                                        />

                                        <FieldInputComponent
                                            name={"Compra/Venda"}
                                            inputValue={compraVenda}
                                            inputType={'text'}
                                            inputName={'compraVenda'}
                                            handleFocus={handleFocus}
                                            handleChange={handleChange}
                                            placeholder={'C/V'}
                                            className={'fas fa-truck-loading'}
                                        />

                                        <FieldInputComponent
                                            name={"Quantidade"}
                                            inputValue={quantidade}
                                            inputType={'number'}
                                            inputName={'quantidade'}
                                            handleFocus={handleFocus}
                                            handleChange={handleChange}
                                            placeholder={'Quantidade'}
                                            className={'fas fa-layer-group'}
                                        />

                                        <FieldInputComponent
                                            isCurrency={true}
                                            name={"Preço"}
                                            inputValue={preco}
                                            inputType={'number'}
                                            inputName={'preco'}
                                            handleFocus={handleFocus}
                                            handleChange={handleChange}
                                            placeholder={'Preço'}
                                            className={'fas fa-money-bill-alt'}
                                        />

                                        <FieldInputComponent
                                            isCurrency={true}
                                            name={"Corretagem"}
                                            inputValue={corretagem}
                                            inputType={'number'}
                                            inputName={'corretagem'}
                                            handleFocus={handleFocus}
                                            handleChange={handleChange}
                                            placeholder={'Corretagem'}
                                            className={'fas fa-hand-holding-usd'}
                                        />


                                    </div>


                                    <div className="column">

                                        <FieldInputComponent
                                            isCurrency={true}
                                            name={"Valor Total"}
                                            inputValue={valorTotal}
                                            inputType={'number'}
                                            inputName={'valorTotal'}
                                            handleFocus={handleFocus}
                                            handleChange={handleChange}
                                            placeholder={'Valor Total'}
                                            className={'fas fa-coins'}
                                        />

                                        <FieldInputComponent
                                            name={"Corretora"}
                                            inputValue={corretora}
                                            inputType={'text'}
                                            inputName={'corretora'}
                                            handleFocus={handleFocus}
                                            handleChange={handleChange}
                                            placeholder={'Corretora'}
                                            className={'fas fa-warehouse'}
                                        />

                                        <FieldInputComponent
                                            name={"Mercado"}
                                            inputValue={mercado}
                                            inputType={'text'}
                                            inputName={'mercado'}
                                            handleFocus={handleFocus}
                                            handleChange={handleChange}
                                            placeholder={'Mercado'}
                                            className={'fas fa-search-location'}
                                        />

                                        <FieldInputComponent
                                            name={"Especificação"}
                                            inputValue={especificacao}
                                            inputType={'text'}
                                            inputName={'especificacao'}
                                            handleFocus={handleFocus}
                                            handleChange={handleChange}
                                            placeholder={'Especificação'}
                                            className={'fas fa-layer-group'}
                                        />


                                        <FieldInputComponent
                                            name={"Fator Cotação"}
                                            inputValue={fatorCotacao}
                                            inputType={'text'}
                                            inputName={'fatorCotacao'}
                                            handleFocus={handleFocus}
                                            handleChange={handleChange}
                                            placeholder={'Fator Cotação'}
                                            className={'fas fa-balance-scale'}
                                        />

                                        <div className="field">
                                            <div className="control is-expanded has-icons-left">
                                                <br/>
                                                <button className="button is-primary"
                                                        onClick={addNegociacaoAtivo}> Adicionar
                                                </button>
                                            </div>
                                        </div>


                                    </div>


                                </div>
                            </div>
                            <div className="field">
                                <p className="help is-success">{updatedMessage}</p>
                            </div>
                        </form>
                        : ''}
                </article>
            </div>
        </section>
    )
}

export default AdicionarNegociacaoAtivoComponent