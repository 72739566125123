import React, {useState, useContext, useEffect} from 'react';
import {MainContext} from '../../contexts/MainContext';
import useHttpHook from '../../hooks/useHttpHook'
import {useHistory} from 'react-router-dom';
import InfoCard from '../../components/InfoCard/InfoCard';
import FiisListComponent from "./fiisDashboard/fiisListComponent";
import MyDistribuitionComponent from "../../components/myDistribuitionComponent";
import useLanguage from "../../hooks/useLanguageHook";
import MyEarningsComponent from "../../components/myEarningsComponent";
import './Dashboard.css';
import TotalDashboard from "./totalDashboard/TotalDashboard";
import EtfDashboard from "./etfsDashboard/EtfDashboard";
import StocksDashboard from "./stocksDashboard/StocksDashboard";

function DashboardComponent(props) {
    const {httpRequest} = useHttpHook();
    const {userData, LANGUAGE} = useContext(MainContext);
    const [fiis, updateFiis] = useState([]);
    const [userStockSheet, updateUserStockSheet] = useState([]);
    const [ativos, updateAtvivos] = useState([]);
    const [componentLoaded, changeComponentLoaded] = useState(true);
    const [rentabilidadeFiis, updateRentabilidadeFii] = useState('');
    const [avgDividendYield, updateAvgDividendYield] = useState('');
    const [activeStock, updateActiveStock] = useState('total');
    const {languageData} = useLanguage();
    const [earnings, setEarnings] = useState({creditos: [], provisoes:[]})
    useEffect(() => {
        changeComponentLoaded(true);
    }, []);

    useEffect(() => {
        parseFiisHistories();
    }, [fiis])

    useEffect(() => {
        if (componentLoaded && userData && userData.userId) {
            getResumedFiis()
                .then(() => {
                    return getResumedSheet();
                })
                .then(() => {
                    return getEarnings();
                });
        }
    }, [userData, componentLoaded, LANGUAGE]);

    useEffect(() => {
        parseDistribuicaoAtivos();
    }, [userStockSheet]);

    if (!componentLoaded) {
        return (<div></div>)
    }

    function parseFiisHistories() {

        let yieldMedio = 0;
        let fiiCount = 0;
        let rentabilidadeFiis = 0;
        let avgDividendYield = '';

        if (fiis.userFiiStocks) {
            Object.keys(fiis.userFiiStocks).map((value, key) => {
                let fii = fiis.userFiiStocks[value];
                if (fii.quantity && fii.earningsPerShare) {
                    rentabilidadeFiis += fii.earningsPerShare * fii.quantity;
                    yieldMedio += fii.dividendYield;
                    fiiCount++;
                }
            })
        }
        rentabilidadeFiis = Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(rentabilidadeFiis);
        if (fiiCount && fiiCount > 0) {
            avgDividendYield = ((yieldMedio / fiiCount)).toFixed(2) + '%';
        } else {
            avgDividendYield = '0%';
        }
        updateRentabilidadeFii(rentabilidadeFiis);
        updateAvgDividendYield(avgDividendYield);

    }

    function parseDistribuicaoAtivos() {
        const tipoAtivos = {};
        userStockSheet.forEach((stock) => {
            if (!tipoAtivos[stock.stockType]) {
                tipoAtivos[stock.stockType] = {
                    stockType: stock.stockType,
                    percentage: stock.havePercentage,
                    haveAmount: stock.haveAmount,
                };
            } else {
                tipoAtivos[stock.stockType].percentage += stock.havePercentage;
                tipoAtivos[stock.stockType].haveAmount += stock.haveAmount;
            }
        });
        const distribuicaoAtivos = [];
        let totalAmount = 0;
        if (tipoAtivos['etf']) {
            tipoAtivos['etf'].stockTypeId = 'etf';
            tipoAtivos['etf'].stockType = 'ETF';
            tipoAtivos['etf'].percentage = ((tipoAtivos['etf'].percentage) * 100).toFixed(2) + '%';
            tipoAtivos['etf'].parsedAmount = new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
            }).format(tipoAtivos['etf'].haveAmount);
            totalAmount += tipoAtivos['etf'].haveAmount;
            distribuicaoAtivos.push(tipoAtivos['etf']);
        }
        if (tipoAtivos['fii']) {
            tipoAtivos['fii'].stockTypeId = 'fii';
            tipoAtivos['fii'].stockType = languageData.dashboard.fiisShort;
            tipoAtivos['fii'].percentage = ((tipoAtivos['fii'].percentage) * 100).toFixed(2) + '%';
            tipoAtivos['fii'].parsedAmount = new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
            }).format(tipoAtivos['fii'].haveAmount);
            totalAmount += tipoAtivos['fii'].haveAmount;

            distribuicaoAtivos.push(tipoAtivos['fii']);
        }
        if (tipoAtivos['stock']) {
            tipoAtivos['stock'].stockTypeId = 'stock';
            tipoAtivos['stock'].stockType = languageData.dashboard.stocks;
            tipoAtivos['stock'].percentage = ((tipoAtivos['stock'].percentage) * 100).toFixed(2) + '%';
            tipoAtivos['stock'].parsedAmount = new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
            }).format(tipoAtivos['stock'].haveAmount)
            totalAmount += tipoAtivos['stock'].haveAmount;

            distribuicaoAtivos.push(tipoAtivos['stock']);
        }

        tipoAtivos['total'] = {
            stockTypeId: 'total',
            stockType: 'Total',
            percentage: '100%',
            haveAmount: totalAmount,
            parsedAmount: new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(totalAmount)
        };

        distribuicaoAtivos.push(tipoAtivos['total']);

        updateAtvivos(distribuicaoAtivos);
    }

    function getEarnings() {
        httpRequest('GET', `/users/${userData.userId}/earnings`,
            null)
            .then((data) => {
                // console.log(data);
                console.log(data);
                setEarnings(data);
                return '';
            })
            .catch((err) => {
                console.log(err);
                return '';
            });
    }

    function getResumedSheet() {
        httpRequest('GET', `/users/${userData.userId}/resumed-sheet`,
            null)
            .then((data) => {
                // console.log(data);
                updateUserStockSheet(data);
                return '';
            })
            .catch((err) => {
                console.log(err);
                return '';
            });
    }

    function getResumedFiis() {
        return httpRequest('GET', `/users/${userData.userId}/fiis`,
            null)
            .then((data) => {
                // console.log(data);
                return updateFiis(data);
            })
            .catch((err) => {
                console.log(err);
                return '';
            })
    }

    return (
        <div>
            <div className="container">
                <div className="columns">
                    <div className="column is-12 ">
                        <section className="info-tiles">
                            <br/>
                            <div className="tile is-ancestor has-text-centered stocks-orientation">
                                {ativos.map((ativo, key) => {
                                    return (
                                        <InfoCard key={key}
                                                  updateActiveFunction={updateActiveStock}
                                                           data={{title: ativo.stockType, value: ativo.parsedAmount, activeStock: activeStock, stockTypeId: ativo.stockTypeId}}/>
                                    )
                                })}
                            </div>
                        </section>
                        <div className="columns">

                            <div className={`column is-12 ${activeStock === 'fii' ? '' : 'is-not-active'}`}>
                                <FiisListComponent fiis={fiis} rentabilidadeFiis={rentabilidadeFiis}
                                                   avgDividendYield={avgDividendYield}/>
                            </div>
                            <div className={`column is-12 ${activeStock === 'total' ? '' : 'is-not-active'}`}>
                                <TotalDashboard ativos={ativos} earnings={earnings}/>
                            </div>
                            <div className={`column is-12 ${activeStock === 'stock' ? '' : 'is-not-active'}`}>
                                <StocksDashboard/>
                            </div>
                            <div className={`column is-12 ${activeStock === 'etf' ? '' : 'is-not-active'}`}>
                                <EtfDashboard/>
                            </div>
                        </div>
                    </div>

                </div>
                {/*<div className='columns'>*/}
                {/*    <div className="column is-12 ">*/}
                {/*        <MyEarningsComponent earnings={earnings}/>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>

    )

}

export default DashboardComponent