import React, {useContext, useEffect, useState} from "react";
import CurrencyInput from "react-currency-input";
import useHttpHook from "../hooks/useHttpHook";
import {MainContext} from "../contexts/MainContext";
import { toast } from 'react-toastify';

function AdicionarAtivoComponent() {
    const {httpRequest} = useHttpHook();
    const {userData, LANGUAGE, userStocks} = useContext(MainContext);
    const [componentLoaded, changeComponentLoaded] = useState(false);
    const [userBudget, updateUserBudget] = useState({});
    const [isLoaded, switchLoadade] = useState(false);
    const [unusedBudget, updateUnusedBudget] = useState(0);
    const [ativo, setAtivo] = useState('');
    const [quantidade, setQuantidade] = useState('');
    const [score, setScore] = useState('');
    const [updatedMessage, setUpdatedMessage] = useState('');
    useEffect(() => {
        changeComponentLoaded(true);
    }, []);


    useEffect(() => {
        if (componentLoaded && userData && userData.userId) {

        }
    }, [userData, componentLoaded, LANGUAGE]);

    const handleFocus = (event) => event.target.select();

    function handleSubmitBudget(event) {
        event.preventDefault();
    }

    function handleChange(event) {
        console.log(event.target.name);
        setUpdatedMessage('');
        switch (event.target.name) {
            case 'ativo':
                event.target.value = event.target.value.toUpperCase();
                if(userStocks.length && event.target.name.length > 3){
                    for(let userStock of userStocks){
                        if(userStock.stockId === event.target.value){
                            setScore(userStock.score);
                        }
                    }
                }
                setAtivo(event.target.value);
                // toggleDisabled();
                break;
            case 'score':
                setScore(event.target.value);
                // toggleDisabled();
                break;
            case 'quantidade':
                setQuantidade(event.target.value);
                // toggleDisabled();
                break;
            default:
                break;
        }
    }

    async function addUpdateStock(event){
        event.preventDefault();
        try {
            await httpRequest('PUT', `/users/${userData.userId}/stocks/${ativo.toUpperCase()}/addStocks`, {
                score: Number.parseInt(score),
                quantity: Number.parseInt(quantidade)
            });
            setAtivo('');
            setQuantidade('');
            setScore('');
            // setUpdatedMessage('Ativo inserido/atualizado com sucesso');
            toast.success('Ativo inserido/atualizado com sucesso')

        } catch (err) {
            console.log(err);
        }
    }


    return (
        <section className="">

            <div className="">
                <article className="tile is-child box">
                    <h3>Adicionar/incrementar ativo</h3>
                    <br/>
                    <form onSubmit={handleSubmitBudget}>
                        <div className="field is-horizontal">
                            <div className="field-body">
                                <div className="field">
                                    <p className="control is-expanded has-icons-left">
                                        <input className="input" value={ativo} type="text"
                                                       name="ativo" onFocus={handleFocus}
                                                       onChange={handleChange}
                                               placeholder="Ativo"
                                                      />

                                        <span className="icon is-small is-left">
                                                      <i className="fas fa-money-bill-alt"/>
                                                    </span>
                                    </p>
                                </div>

                                <div className="field">
                                    <p className="control is-expanded has-icons-left">
                                        <input className="input" value={quantidade} type="number"
                                               name="quantidade" onFocus={handleFocus}
                                               onChange={handleChange}
                                               placeholder="Quantidade"
                                        />

                                        <span className="icon is-small is-left">
                                                      <i className="fas fa-layer-group"/>
                                                    </span>
                                    </p>
                                </div>

                                <div className="field">
                                    <p className="control is-expanded has-icons-left">
                                        <input className="input" value={score} type="number"
                                               name="score" onFocus={handleFocus}
                                               onChange={handleChange}
                                               placeholder="Nota"
                                        />

                                        <span className="icon is-small is-left">
                                                      <i className="fas fa-star"/>
                                                    </span>
                                    </p>
                                </div>
                                <div className="control">
                                    <button className="button is-primary" onClick={addUpdateStock} >Atualizar
                                    </button>
                                </div>


                            </div>
                        </div>
                        <div className="field">
                            <p className="help is-success">{updatedMessage}</p>
                        </div>
                    </form>
                </article>
            </div>
        </section>
    )
}

export default AdicionarAtivoComponent