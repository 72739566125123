import React from "react";
import CurrencyInput from "react-currency-input";
import MaskInput from 'react-maskinput';
function FieldInputComponent(props) {

    return <div className="field">
        <p className="is-small">{props.name}</p>
        <p className="control is-expanded has-icons-left">
            {props.isCurrency ?
                <CurrencyInput className="input" value={props.inputValue}
                               name={props.inputName} onFocus={props.handleFocus}
                               onChangeEvent={(event)=>{props.handleChange(event)}}
                               decimalSeparator="," thousandSeparator="."
                               prefix="R$"
                               placeholder="R$123,00"/> :
                props.isDate ?
                    <MaskInput className="input" onFocus={props.handleFocus} onValueChange={props.setOnValueChange} value={props.inputValue} name={props.inputName} alwaysShowMask onChange={props.onChange} maskString={props.maskString} mask={props.mask} size={20} />
                :

                <input className="input" value={props.inputValue} type={props.inputType}
                       name={props.inputName} onFocus={props.handleFocus}
                       onChange={props.handleChange}
                       placeholder={props.placeholder}
                />
            }

            <span className="icon is-small is-left">
                <i className={props.className}/>
            </span>
        </p>
    </div>
}

export default FieldInputComponent