import React, {useContext, useState} from "react";
import useLanguage from "../../hooks/useLanguageHook";
import {MainContext} from "../../contexts/MainContext";
import {useHistory} from "react-router-dom";
import './navBarLogged.css';

function NavBarLoggedComponent(props) {
    const {languageData} = useLanguage();
    const {setJwt, LANGUAGE, changeLanguage} = useContext(MainContext);
    const history = useHistory();
    const [isMobileMenuActive, changeIsMenuActive] = useState(false);
    const [currentActive, changeCurrentActive] = useState('dashboard');

    function logout() {
        setJwt(null);
    }

    return (
        <div>
            <nav className="navbar" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <a role="button" className={`navbar-burger burger ${isMobileMenuActive ? 'is-active' : ''}`}
                       aria-label="menu" aria-expanded="false"
                       data-target="navbarBasePage" onClick={() => {
                        changeIsMenuActive(!isMobileMenuActive)
                    }}>
                        <span aria-hidden="true"/>
                        <span aria-hidden="true"/>
                        <span aria-hidden="true"/>
                    </a>
                </div>


                <div id="navbarBasePage" className={`navbar-menu ${isMobileMenuActive ? 'is-active' : ''}`}>
                    <div className="navbar-start">
                        <a className={`navbar-item is-tab ${currentActive === 'dashboard' ? 'is-active' : ''}`}
                           onClick={() => {
                               history.push('/dashboard');
                               ;changeCurrentActive('dashboard')
                           }}>
                            {languageData.dashboard.title}
                        </a>

                        <a className={`navbar-item is-tab ${currentActive === 'my-wallet' ? 'is-active' : ''}`}
                           onClick={() => {
                               history.push('/my-wallet');
                               changeCurrentActive('my-wallet')
                           }}>
                            {languageData.myWallet.title}
                        </a>

                        <a className={`navbar-item is-tab ${currentActive === 'asset-trading' ? 'is-active' : ''}`}
                           onClick={() => {
                               history.push('/asset-trading');
                               changeCurrentActive('asset-trading')
                           }}>
                            {languageData.assetTrading.title}
                        </a>

                    </div>

                    {isMobileMenuActive ?
                        <div>
                            <div className={"navbar-item is-tab"} onClick={() => {
                                history.push('/my-account');
                                changeCurrentActive('my-account')
                            }}>
                                <a><i className="fas fa-cog"/> </a>
                            </div>
                            <div className="navbar-item is-tab">

                                <a className="button is-light" onClick={() => {
                                    logout();
                                    changeCurrentActive('logout')
                                }}>
                                    {languageData.logout}
                                </a>
                            </div>
                        </div>
                        :


                        <div className="navbar-end">
                            <div className="navbar-item ">
                                <div className="buttons">
                                    <div className={`navbar-item is-tab ${currentActive === 'my-account' ? 'is-active' : ''}`}>
                                        <a onClick={() => {
                                            history.push('/my-account');
                                            changeCurrentActive('my-account')
                                        }}><i className="fas fa-cog"/> </a>
                                    </div>
                                    <div className="navbar-item has-dropdown is-hoverable ">
                                        <a className="navbar-link is-arrowless">
                                            {LANGUAGE === 'pt-BR' ? <span className="flag-icon flag-icon-br"/> :
                                                <span className="flag-icon flag-icon-us"/>}
                                        </a>
                                        <div className="navbar-dropdown">
                                            <a className="navbar-item" onClick={() => {
                                                changeLanguage('pt-BR')
                                            }}>
                                                <span className="flag-icon flag-icon-br"/>
                                            </a>

                                            <a className="navbar-item" onClick={() => {
                                                changeLanguage('en-US')
                                            }}>
                                                <span className="flag-icon flag-icon-us"/>
                                            </a>
                                        </div>
                                    </div>
                                    <div className={`navbar-item `} onClick={() => {
                                        logout()
                                    }}>

                                        <a className="button is-light" >
                                            {languageData.logout}
                                        </a>
                                    </div>


                                </div>
                            </div>


                        </div>}
                </div>
            </nav>
            {props.children}
        </div>
    )
}

export default NavBarLoggedComponent