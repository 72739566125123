import React, {useContext} from 'react';
import './App.css';
import GameTypingComponent from './pages/gameTypingComponent'
import {Switch, Route} from 'react-router-dom';
import HomeComponent from './pages/homeComponent'
import RedirectComponent from './pages/redirectComponent'
import {MainContext} from "./contexts/MainContext";
import AuthComponent from "./pages/authComponent";
import DashboardComponent from './pages/dashboard/dashboardComponent';
import NavBarLoggedComponent from "./pages/navBarLogged/navBarLoggedComponent";
import MyWalletComponent from "./pages/myWalletComponent";
import AssetTradingComponent from "./pages/assetTradingComponent";
import NavBarNotLoggedComponent from "./pages/navBarNotLoggedComponent";
import MyAccountComponent from "./pages/myAccountComponent";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";

function App() {
    const {isLoading} = useContext(MainContext);

    return (
        <div>
            {/*<p>{isLoading ? "Loading" : "Nao loading"}</p>*/}
            <ToastContainer />
            <Switch>
                <Route exact path='/'>
                    <RedirectComponent/>
                </Route>
                <Route exact path='/home'>
                    <NavBarNotLoggedComponent>
                        <HomeComponent/>
                    </NavBarNotLoggedComponent>
                </Route>

                <AuthComponent>
                    <NavBarLoggedComponent>
                        <Route path='/dashboard'>
                            <DashboardComponent/>
                        </Route>
                        <Route path='/my-wallet'>
                            <MyWalletComponent/>
                        </Route>
                        <Route path='/asset-trading'>
                            <AssetTradingComponent/>
                        </Route>
                        <Route path='/my-account'>
                            <MyAccountComponent/>
                        </Route>
                    </NavBarLoggedComponent>
                </AuthComponent>
                {/*<Route path='/dashboard'>*/}
                {/*    <AuthComponent>*/}
                {/*        <NavBarLoggedComponent>*/}
                {/*            <DashboardComponent/>*/}
                {/*        </NavBarLoggedComponent>*/}
                {/*    </AuthComponent>*/}
                {/*</Route>*/}
                {/*<Route path='/my-wallet'>*/}
                {/*    <AuthComponent>*/}
                {/*        <NavBarLoggedComponent>*/}
                {/*            <MyWalletComponent/>*/}
                {/*        </NavBarLoggedComponent>*/}
                {/*    </AuthComponent>*/}
                {/*</Route>*/}
                {/*<Route path='/asset-trading'>*/}
                {/*    <AuthComponent>*/}
                {/*        <NavBarLoggedComponent>*/}
                {/*            <AssetTradingComponent/>*/}
                {/*        </NavBarLoggedComponent>*/}
                {/*    </AuthComponent>*/}
                {/*</Route>*/}
            </Switch>
        </div>
    );
}

export default App;
