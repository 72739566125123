import React, {useEffect, useState} from "react";
import useLanguage from "../hooks/useLanguageHook";
import * as shortuuid from 'short-uuid';
import moment from 'moment';

function MyEarningsComponent(props) {
    const {languageData} = useLanguage();
    const earnings = props.earnings;
    const [totalToReceive, setTotalToReceive] = useState(0);
    const [totalCredits, setTotalCredits] = useState(0);

    useEffect(() => {
        let totalAReceber = 0;
        let totalCreditos = 0;
        for (let provisao of props.earnings.provisoes) {
            totalAReceber += provisao.netValue;
        }
        for (let credito of props.earnings.creditos) {
            totalCreditos += credito.netValue;
        }
        setTotalToReceive(totalAReceber);
        setTotalCredits(totalCreditos);
    }, [props.earnings]);
    console.log(earnings);
    return (
        <div>


            <div className="card">
                <header className="card-header">
                    <p className="card-header-title">
                        Proventos no mês
                    </p>
                </header>
                <div className="card-content">
                    <div className="content">
                        <table className="table is-fullwidth is-striped">
                            <tbody>
                            {/*{earnings.provisoes.map((provisao) => {*/}
                            {/*    return (*/}
                            {/*        <tr key={provisao.stockId + '-' + shortuuid.generate()}>*/}
                            {/*            <td width="5%"/>*/}
                            {/*            <td>{provisao.stockId} <span*/}
                            {/*                className="td-small-title">Data: {moment(provisao.dueAtPayment).format('DD/MM/YYYY')} - Tipo: {provisao.type} - Quantidade base: {provisao.baseQuantity} </span>*/}
                            {/*            </td>*/}
                            {/*            <td className="level-right">*/}
                            {/*            <span*/}
                            {/*                className="button is-small is-info">{new Intl.NumberFormat('pt-BR', {*/}
                            {/*                style: 'currency',*/}
                            {/*                currency: 'BRL'*/}
                            {/*            }).format(provisao.netValue)}</span>*/}
                            {/*            </td>*/}
                            {/*        </tr>*/}
                            {/*    )*/}
                            {/*})*/}
                            {/*}*/}
                            <tr key={shortuuid.generate()}>
                                <td width="5%"/>
                                <td>Total a receber</td>
                                <td className="level-right">
                                    <a
                                        className="button is-small is-info">{new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    }).format(totalToReceive)}</a>
                                </td>
                            </tr>
                            <tr key={shortuuid.generate()}>
                                <td width="5%"/>
                                <td>Total recebido</td>
                                <td className="level-right">
                                    <a
                                        className="button is-small is-primary">{new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    }).format(totalCredits)}</a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            {/*<div className="card">*/}
            {/*    <header className="card-header">*/}
            {/*        <p className="card-header-title">*/}
            {/*            Créditos*/}
            {/*        </p>*/}
            {/*    </header>*/}
            {/*    <div className="card-content">*/}
            {/*        <div className="content">*/}
            {/*            <table className="table is-fullwidth is-striped">*/}
            {/*                <tbody>*/}
            {/*                {earnings.creditos.map((provisao) => {*/}
            {/*                    console.log(provisao);*/}
            {/*                    return (*/}
            {/*                        <tr key={provisao.stockId + '-' + shortuuid.generate()}>*/}
            {/*                            <td width="5%"/>*/}
            {/*                            <td>{provisao.stockId} <span*/}
            {/*                                className="td-small-title">Data: {moment(provisao.date).format('DD/MM/YYYY')} - Tipo: {provisao.type} - Quantidade base: {provisao.baseQuantity} </span>*/}
            {/*                            </td>*/}
            {/*                            <td className="level-right">*/}
            {/*                            <span*/}
            {/*                                className="button is-small is-info">{new Intl.NumberFormat('pt-BR', {*/}
            {/*                                style: 'currency',*/}
            {/*                                currency: 'BRL'*/}
            {/*                            }).format(provisao.netValue)}</span>*/}
            {/*                            </td>*/}
            {/*                        </tr>*/}
            {/*                    )*/}
            {/*                })*/}
            {/*                }*/}
            {/*                <tr key={shortuuid.generate()}>*/}
            {/*                    <td width="5%"/>*/}
            {/*                    <td>Total recebido</td>*/}
            {/*                    <td className="level-right">*/}
            {/*                        <a*/}
            {/*                            className="button is-small is-primary">{new Intl.NumberFormat('pt-BR', {*/}
            {/*                            style: 'currency',*/}
            {/*                            currency: 'BRL'*/}
            {/*                        }).format(totalCredits)}</a>*/}
            {/*                    </td>*/}
            {/*                </tr>*/}
            {/*                </tbody>*/}
            {/*            </table>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>

    )
}

export default MyEarningsComponent