import React from "react";
import AporteComponent from "../components/aporteComponent";
import MyFinantialTableComponent from "../components/myFinantialTableComponent";
import AdicionarAtivoComponent from "../components/adicionarAtivoComponent";

function MyWalletComponent() {


    return (
        <div>
            <div className="container">
                <div className="columns">
                    <div className="column is-4 ">
                        <AporteComponent/>
                    </div>
                    <div className="column is-8 ">
                        <AdicionarAtivoComponent/>
                    </div>
                </div>
            </div>
            <div className="container">

                <div className="column is-full is-desktop is-vcentered">
                    <section className="info-tiles section-smart-table-flex-center-row">
                        <MyFinantialTableComponent/>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default MyWalletComponent