import React, {useState, useEffect} from 'react';
import FiiListDetailsComponent from "./fiiListDetailsComponent";
import ToggleAngleComponent from '../../../components/toggleAngleComponent';
import useLanguage from "../../../hooks/useLanguageHook";

function FiisListComponent(props) {
    let fiis = props.fiis;
    const rentabilidadeFiis = props.rentabilidadeFiis;
    const avgDividendYield = props.avgDividendYield;
    const [showList, changeShowList] = useState(true);
    const {languageData} = useLanguage();
    if (!fiis.userFiiStocks) {
        fiis.userFiiStocks = {};
        fiis.totalToReceive = 0;
    }
    return (
        <div className="card events-card">

            <header className="card-header">
                <p className="card-header-title">
                    {languageData.dashboard.fiis}
                </p>
                {/*<ToggleAngleComponent showList={showList} changeShowList={changeShowList}  />*/}
            </header>
            <div className="card-table">
                <div className="content">
                    <table className="table is-fullwidth is-striped">
                        <tbody>
                        {Object.keys(fiis.userFiiStocks).map((value, key) => {
                            if (fiis.userFiiStocks[value].dividendYield) {
                                return <FiiListDetailsComponent data={{value: fiis.userFiiStocks[value], key}}
                                                                key={key}/>
                            }
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
            <footer className="card-footer">
                <p className="card-footer-item">{languageData.dashboard.averageTotal} {rentabilidadeFiis}/{languageData.dashboard.month} / {avgDividendYield} DY {languageData.dashboard.average}</p>
            </footer>
        </div>
    )
}

export default FiisListComponent