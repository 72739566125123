import React, {useContext, useState, useEffect} from "react";
import PropTypes from 'prop-types';
import CurrencyInput from "react-currency-input";
import * as shortuuid from 'short-uuid';
import * as moment from 'moment';
import * as _ from 'lodash';

function SmartTableComponent(props) {
    const settings = props.settings;
    let data = props.data;
    const reloadFunction = props.reloadFunction;
    const rowUpdateFunction = props.rowUpdateFunction;
    const [currentKey, setCurrentKey] = useState(Object.keys(settings.columns)[0]);
    const [currentSort, setCurrentSort] = useState({key: Object.keys(settings.columns)[0], sort: 'desc'});
    const [sortId] = useState(shortuuid.generate());
    const [dataTable, setDataTable] = useState(data);

    useEffect(() => {
        // console.log(currentKey);
        return updateSort({key: currentKey, sort: 'desc'});
    }, []);

    // array(currentKey, data, 'asc');
    useEffect(() => {
        // console.log(currentKey, currentSort);
        if (currentKey.indexOf(sortId) > -1) {
            // console.log('same key');
            return updateSort({key: currentKey.replace(sortId, ''), sort: 'asc'});
        } else {
            // console.log('different key desc');
            return updateSort({key: currentKey, sort: 'desc'});
        }
    }, [currentKey]);

    useEffect(() => {
        // console.log(currentKey);
        setDataTable(array(currentSort.key, data, currentSort.sort));
    }, [updateSort, currentSort]);

    function updateSort(newSort) {
        setCurrentSort({key: newSort.key, sort: newSort.sort});
    }

    const handleFocus = (event) => event.target.select();
    function handleChange(event) {
        //todo: figure out how to update properly and fast using input instead of updateRowValue
        let input = event.currentTarget.parentNode.getElementsByTagName("input")
        let stockId = input[0].attributes[0].nodeValue.replace('input-','');
        let fieldToUpdate = input[0].attributes[2].nodeValue;
        let dataTableCopy = _.clone(dataTable);
        for(let stock of dataTableCopy){
            if(stock.stockId === stockId){
                console.log('AUUUU')
                if(stock[fieldToUpdate] !== event.target.value){
                    stock[fieldToUpdate] = event.target.value;
                }
            }
        }
        setDataTable(dataTableCopy);
        event.target.select()
    }
    function array(key, array, sortBy = 'asc') {
        // console.log('RODANDO SORT');
        const a = array.sort(function (a, b) {
            let keyA = (a[key]),
                keyB = (b[key]);

            if (settings.columns[key] && settings.columns[key].sortType && settings.columns[key].sortType === 'dateFormat') {
                keyA = moment(keyA, settings.columns[key].sortFormat);
                keyB = moment(keyB, settings.columns[key].sortFormat);
                if (sortBy === 'asc') {
                    if (keyA.isBefore(keyB)) return -1;
                    if (keyA.isAfter(keyB)) return 1;
                    return 0;
                } else {
                    if (keyA.isAfter(keyB)) return -1;
                    if (keyA.isBefore(keyB)) return 1;
                    return 0;
                }
            } else if (settings.columns[key] && settings.columns[key].sortType && settings.columns[key].sortType === 'number') {
                keyA = Number.parseFloat(keyA);
                keyB = Number.parseFloat(keyB);
                if (sortBy === 'asc') {
                    return keyA >= keyB ? 1: -1;
                } else {
                    return keyB >= keyA ? 1 : -1;
                }
            } else {

                // Compare the 2 dates
                if (sortBy === 'asc') {
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0;
                } else {
                    if (keyA > keyB) return -1;
                    if (keyA < keyB) return 1;
                    return 0;
                }
            }
        });
        // console.log('cheguei aqui');
        return a;
    }

    return (
        <table className="table">
            <thead>
            <tr>
                {Object.keys(settings.columns).map(function (key, index) {
                    const column = settings.columns[key];
                    return (
                        <th key={key} onClick={(event) => {
                            key === currentKey ?
                                setCurrentKey(sortId + key) : setCurrentKey(key)
                        }} style={{cursor: 'pointer'}}>{column.title}

                             {/*{currentSort.sort === 'asc' ? <span>  <i className="fas fa-arrow-down"/></span>: <span>  <i className="fas fa-arrow-up"/></span>}*/}
                        </th>
                    )
                })}

            </tr>
            </thead>
            <tbody>
            {
                dataTable.map((sheetRow, key) => {
                    const tds = [];
                    {
                        Object.keys(settings.columns).map(function (columnKey, index) {
                            Object.keys(sheetRow).map(function (sheetKey, index) {
                                const value = sheetRow[sheetKey];
                                if (sheetKey === columnKey) {
                                    tds.push(
                                        <td key={settings.columns[columnKey] + `td-${sheetKey}-${shortuuid.generate()}`}
                                            contentEditable={settings.columns[columnKey] && settings.columns[columnKey].isEditable ? "true" : "false"}
                                            suppressContentEditableWarning={true}
                                            onKeyPress={rowUpdateFunction.bind(this)}

                                            className={settings.columns[columnKey] && settings.columns[columnKey].isEditable ?'hover-editable' : ''}
                                            style={{cursor: settings.columns[columnKey] && settings.columns[columnKey].isEditable ? 'pointer' : '',
                                                // 'paddingTop' : settings.columns[columnKey] && settings.columns[columnKey].isEditable ? '0': ''
                                            }
                                            }>
                                            {/*{settings.columns[columnKey] && settings.columns[columnKey].isEditable ?*/}
                                            {/*    <input id={`input-${sheetRow.stockId}`} type="number" value={settings.columns[columnKey].valuePrepareFunction ? settings.columns[columnKey].valuePrepareFunction(value) || '' : value || ''} name={sheetKey}*/}
                                            {/*           onFocus={handleFocus}*/}
                                            {/*           onChange={handleChange}*/}
                                            {/*           className={`input smart-table-input`} required/>*/}
                                            {/*     : settings.columns[columnKey].valuePrepareFunction ? settings.columns[columnKey].valuePrepareFunction(value) : value}*/}
                                            {settings.columns[columnKey].valuePrepareFunction ?settings.columns[columnKey].valuePrepareFunction(value) : value}
                                        </td>);
                                }
                            });
                        })
                    }
                    return (
                        <tr key={`tr` + sheetRow.stockId + `${shortuuid.generate()}`} className="smartTableTr">
                            {tds.map((td) => {
                                return td;
                            })}
                            {settings.columns['actions'] ? <td key={settings.columns['actions']+shortuuid.generate()}>
                                <button className="button is-danger is-light is-small" onClick={()=>{settings.columns['actions'].valuePrepareFunction(sheetRow.stockId)}}>X</button>
                            </td> : ''}
                        </tr>
                    )
                })
            }
            </tbody>
        </table>
    )

}

SmartTableComponent.propTypes = {
    settings: PropTypes.object.isRequired,
    data: PropTypes.array
};

export default SmartTableComponent