
import React, {useState, useContext, useEffect} from 'react';
import {MainContext} from '../../contexts/MainContext';
import useHttpHook from '../../hooks/useHttpHook'
import {useHistory} from 'react-router-dom';
import useLocalStorage from "../../hooks/useLocalStorage";
import useLanguage from "../../hooks/useLanguageHook";
function LoginComponent(){

    const {httpRequest} = useHttpHook();
    const [email, changeEmail] = useState('');
    const [password, changePassword] = useState('');
    const [isDisabled, changeDisabled] = useState(true);
    const {setJwt, jwt} = useContext(MainContext);
    const history = useHistory();
    const [jwtLocal, changeJwtLocal] = useLocalStorage('', 'jwt')
    const {languageData} = useLanguage();

    useEffect(()=>{
        toggleDisabled();
    }, [])
    function toggleDisabled() {
        if(email !== '' && password !== ''){
            changeDisabled(false);
        }else{
            changeDisabled(true);
        }
    }

    function handleChange(event) {
        switch (event.target.name) {
            case 'email':
                changeEmail(event.target.value);
                toggleDisabled();
                break;
            case 'password':
                changePassword(event.target.value);
                toggleDisabled();
                break;
            default:
                break;
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();

        const response = await httpRequest('POST', '/auth',
            {"email": email, "password": password});
        if(response && response.id){
            setJwt(response.id);
            changeJwtLocal(response.id);
            history.push('/dashboard')
        }else{
            console.log(response);
        }

    }

    return (
        <div className="column is-5-tablet is-4-desktop is-5-widescreen">
            <form className="box">
                <div className="field">
                    <label htmlFor="" className="label">{languageData.home.email}</label>
                    <div className="control has-icons-left">
                        <input type="email" value={email} name="email"
                               onChange={handleChange}
                               placeholder={languageData.home.emailDescription} className="input"
                               required/>
                        <span className="icon is-small is-left">
                                          <i className="fa fa-envelope"/>
                                        </span>
                    </div>
                </div>
                <div className="field">
                    <label htmlFor="" className="label">{languageData.home.password}</label>
                    <div className="control has-icons-left">
                        <input type="password" value={password} name="password"
                               onChange={handleChange} placeholder={languageData.home.passwordDescription}
                               className="input" required/>
                        <span className="icon is-small is-left">
                                          <i className="fa fa-lock"/>
                                        </span>
                    </div>
                </div>
                {/*<div className="field">*/}
                {/*    <label htmlFor="" className="checkbox">*/}
                {/*        <input type="checkbox"/>*/}
                {/*        <span> </span>Remember me*/}
                {/*    </label>*/}
                {/*</div>*/}
                <div className="field">
                    <button type="submit" onClick={handleSubmit} disabled={isDisabled}
                            className="button is-success">{languageData.home.login}</button>
            </div>
            </form>
        </div>
    )
}

export default LoginComponent