import React, {useState, useContext, useEffect} from 'react';
import {MainContext} from '../contexts/MainContext';
import useHttpHook from '../hooks/useHttpHook'
import {useHistory} from 'react-router-dom';
import InfoCardComponent from '../components/InfoCard/InfoCard';
import FiisListComponent from "./dashboard/fiisDashboard/fiisListComponent";
import MyDistribuitionComponent from "../components/myDistribuitionComponent";
import useLanguage from "../hooks/useLanguageHook";
import MyEarningsComponent from "../components/myEarningsComponent";
import NewUserComponent from "./home/newUserComponent";
import ImportCeiComponent from "../components/importCeiComponent";

function MyAccountComponent(props) {
    const {httpRequest} = useHttpHook();
    const {userData, LANGUAGE} = useContext(MainContext);
    const [userInfo, updateUserData] = useState({email: '', firstName: '', lastName: ''});
    const [componentLoaded, changeComponentLoaded] = useState(true);
    const [isDisabled, changeDisabled] = useState(true);
    const [updateMessage, setUpdateMessaged] = useState('');
    const {languageData} = useLanguage();

    useEffect(() => {
        changeComponentLoaded(true);
    }, []);


    useEffect(() => {
        if (componentLoaded && userData && userData.userId) {

            httpRequest('GET', `/users/me`,
                null)
                .then((data) => {
                    console.log(data);
                    // return updateFiis(data);
                    updateUserData(data);
                    toggleDisabled();
                    return data;
                })
                .catch((err) => {
                    console.log(err);
                    return '';
                })
        }
    }, [userData, componentLoaded, LANGUAGE]);


    if (!componentLoaded) {
        return (<div></div>)
    }

    function handleChange(event) {
        switch (event.target.name) {
            case 'firstName':
                // changeEmail(event.target.value);
                updateUserData({firstName: event.target.value, lastName: userInfo.lastName, email: userInfo.email});
                setUpdateMessaged('');
                toggleDisabled();
                break;
            case 'lastName':
                updateUserData({firstName: userInfo.firstName, lastName: event.target.value, email: userInfo.email});
                toggleDisabled();
                setUpdateMessaged('');
                break;
            default:
                break;
        }
    }

    function toggleDisabled() {
        console.log(userInfo);
        if (userInfo.firstName !== '' && userInfo.lastName !== '') {
            changeDisabled(false);
        } else {
            changeDisabled(true);
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        //
        await httpRequest('PATCH', `/users/${userData.userId}`,
            {
                firstName: userInfo.firstName,
                lastName: userInfo.lastName
            });
        console.log('boa');
        setUpdateMessaged(languageData.dataUpdatedSuccessMessage);
    }

    return (
        <div>
            <div className="container">
                <div className="columns">
                    <div className="column is-5-tablet is-5-desktop is-5-widescreen">
                        <form className="box">
                            <p><strong>{languageData.myAccount.title}</strong></p>
                            <br/>
                            <div className="field">
                                <div className="control has-icons-left has-icons-right">
                                    <input className="input" type="text" placeholder={languageData.myAccount.email}
                                           onChange={handleChange} disabled={true} value={userInfo.email}/>
                                    <span className="icon is-left">
                                  <i className="fas fa-envelope"/>
                                </span>
                                </div>
                            </div>

                            <div className="field">
                                <div className="control has-icons-left has-icons-right">
                                    <input className="input" type="text" placeholder={languageData.myAccount.firstName}
                                           name="firstName" onChange={handleChange} value={userInfo.firstName}/>
                                    <span className="icon is-left">
                                  <i className="fas fa-user"/>
                                </span>
                                </div>
                            </div>

                            <div className="field">
                                <div className="control has-icons-left has-icons-right">
                                    <input className="input" type="text" placeholder={languageData.myAccount.lastName}
                                           name="lastName" onChange={handleChange} value={userInfo.lastName}/>
                                    <span className="icon is-left">
                                  <i className="fas fa-user"/>
                                </span>
                                </div>
                            </div>

                            <div className="field">
                                <button type="submit" onClick={handleSubmit} disabled={isDisabled}
                                        className="button is-success">{languageData.myAccount.updateButton}</button>
                            </div>
                            <div className="field">
                                <p className="help is-success">{updateMessage}</p>
                            </div>
                        </form>

                    </div>
                    <div className="column is-6-tablet is-6-desktop is-6-widescreen">
                        <ImportCeiComponent/>
                    </div>
                </div>
            </div>
        </div>

    )

}

export default MyAccountComponent