import React, {useState, useContext, useEffect} from 'react';
import {MainContext} from '../../contexts/MainContext';
import useHttpHook from '../../hooks/useHttpHook'
import {useHistory} from 'react-router-dom';
import useLocalStorage from "../../hooks/useLocalStorage";
import useLanguage from "../../hooks/useLanguageHook";

function NewUserComponent() {

    const {httpRequest} = useHttpHook();
    const [email, changeEmail] = useState('');
    const [password, changePassword] = useState('');
    const [confirmPassword, changeConfirmPassword] = useState('');
    const [isDisabled, changeDisabled] = useState(true);
    const {setJwt, jwt} = useContext(MainContext);
    const history = useHistory();
    const [jwtLocal, changeJwtLocal] = useLocalStorage('', 'jwt')
    const {languageData} = useLanguage();
    const [passwordStatus, setPasswordStatus] = useState('');
    const [emailStatus, setEmailStatus] = useState('');
    const [confirmPasswordStatus, setConfirmPasswordStatus] = useState('');
    const [errors, setErrors] = useState('');
    useEffect(() => {
        // if(email)
        if (email.length) {
            const validEmail = isValidEmail(email);
            if (validEmail) {
                setEmailStatus('is-primary');
            } else {
                setEmailStatus('is-danger');
            }
        }
    }, [email]);

    useEffect(() => {
        // if(email)
        if (password.length && password.length > 5) {
            setPasswordStatus('is-primary');
            if(password === confirmPassword){
                setConfirmPasswordStatus('is-primary');
            }
        }else{
            setPasswordStatus('is-danger');
        }
    }, [password]);

    useEffect(() => {
        // if(email)
        if (confirmPassword.length && confirmPassword.length > 5 && password === confirmPassword) {
            setConfirmPasswordStatus('is-primary');
        }else{
            setConfirmPasswordStatus('is-danger');
            setPasswordStatus('is-danger');
        }
    }, [confirmPassword]);

    useEffect(() => {
        toggleDisabled();
    }, [])

    function toggleDisabled() {
        if (email !== '' && password !== '') {
            changeDisabled(false);
        } else {
            changeDisabled(true);
        }
    }

    function isValidEmail(value) {
        return value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    }

    function handleChange(event) {
        switch (event.target.name) {
            case 'email':
                changeEmail(event.target.value);
                toggleDisabled();
                break;
            case 'password':
                changePassword(event.target.value);
                toggleDisabled();
                break;
            case 'confirmPassword':
                changeConfirmPassword(event.target.value);
                toggleDisabled();
                break;
            default:
                break;
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();


       try{
           const createNewUser = await httpRequest('POST', '/users',
               {"email": email, "password": password, "confirmPassword": confirmPassword});
           const response = await httpRequest('POST', '/auth',
               {"email": email, "password": password});
           if(response && response.id){
               setJwt(response.id);
               changeJwtLocal(response.id);
               history.push('/dashboard')
           }else{
               console.log(response);
           }
           console.log(response);
       }catch(err){
           console.log(err);
           if(err.status && err.status === 409){
               setErrors(languageData.home.duplicatedEmailError)
           }else{
               setErrors(languageData.unexpectedError)
           }
       }

        //
        // if (response && response.id) {
        //     setJwt(response.id);
        //     changeJwtLocal(response.id);
        //     history.push('/dashboard')
        // } else {
        //     console.log(response);
        // }

    }

    return (
        <div className="column is-5-tablet is-4-desktop is-5-widescreen">
            <form className="box">
                <div className="field">
                    <label htmlFor="" className="label">{languageData.home.email}</label>
                    <div className="control has-icons-left">
                        <input type="email" value={email} name="email"
                               onChange={handleChange}
                               placeholder={languageData.home.emailDescription} className={`input ${emailStatus}`}
                               required/>
                        <span className="icon is-small is-left">
                                          <i className="fa fa-envelope"/>
                                        </span>
                    </div>
                </div>
                <div className="field">
                    <label htmlFor="" className="label">{languageData.home.password}</label>
                    <div className="control has-icons-left">
                        <input type="password" value={password} name="password"
                               onChange={handleChange} placeholder={languageData.home.passwordDescription}
                               className={`input ${passwordStatus}`} required/>
                        <span className="icon is-small is-left">
                                          <i className="fa fa-lock"/>
                                        </span>
                    </div>
                </div>

                <div className="field">
                    <label htmlFor="" className="label">{languageData.home.confirmPassword}</label>
                    <div className="control has-icons-left">
                        <input type="password" value={confirmPassword} name="confirmPassword"
                               onChange={handleChange} placeholder={languageData.home.confirmPasswordDescription}
                               className={`input ${confirmPasswordStatus}`} required/>
                        <span className="icon is-small is-left">
                                          <i className="fa fa-lock"/>
                                        </span>
                    </div>
                </div>
                {/*<div className="field">*/}
                {/*    <label htmlFor="" className="checkbox">*/}
                {/*        <input type="checkbox"/>*/}
                {/*        <span> </span>Remember me*/}
                {/*    </label>*/}
                {/*</div>*/}
                <div className="field">
                    <button type="submit" onClick={handleSubmit} disabled={isDisabled}
                            className="button is-success">{languageData.home.register}</button>
                </div>
                <div className="field">
                    <p class="help is-danger">{errors}</p>
                </div>
            </form>
        </div>
    )
}

export default NewUserComponent