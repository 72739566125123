import React, {useContext, useEffect, useState} from "react";
import {MainContext} from '../contexts/MainContext.js'
import {useHistory} from "react-router-dom";

function AuthComponent(props) {
    const {jwt} = useContext(MainContext);
    const history = useHistory();
    const [componentLoaded, changeComponentLoaded] = useState(false);

    useEffect(() => {
        changeComponentLoaded(true);
    },[]);

    useEffect(() => {
        if (!jwt || jwt === 'null') {
            history.push('/home')
        }
    }, [jwt]);
    if (!jwt || jwt === 'null') {
        return (<div> </div>)
    }else{
        return (
            <div>
                 {props.children}
             </div>
        )
    }

}

export default AuthComponent