import React from "react";

function EtfDashboard(){
    return (
        <div className="in-construction">
            <p>Página em construção <i className="fas fa-pencil-ruler"/></p>
        </div>
    )
}

export default EtfDashboard;