import React, {useContext, useEffect, useState} from "react";
import CurrencyInput from "react-currency-input";
import useHttpHook from "../hooks/useHttpHook";
import {MainContext} from "../contexts/MainContext";
import { toast } from 'react-toastify';

function AporteComponent() {
    const {httpRequest} = useHttpHook();
    const {userData, LANGUAGE, socket} = useContext(MainContext);
    const [componentLoaded, changeComponentLoaded] = useState(false);
    const [userBudget, updateUserBudget] = useState({});
    const [isLoaded, switchLoadade] = useState(false);
    const [unusedBudget, updateUnusedBudget] = useState(0);
    useEffect(() => {
        changeComponentLoaded(true);
    }, []);


    useEffect(() => {
        if (componentLoaded && userData && userData.userId) {
            getBudget();
            socket.on(userData.userId, (msg) => {
                if (msg && msg.messageId && (msg.messageId === 'ceiImportProcessed' || msg.messageId === 'needRefreshStocks')) {
                    getBudget();
                }
            });
        }

    }, [userData, componentLoaded, LANGUAGE]);

    const handleFocus = (event) => event.target.select();

    function handleSubmitBudget(event) {
        event.preventDefault();
    }

    function handleChange(event) {
        switch (event.target.name) {
            case 'unusedBudget':
                updateUnusedBudget(event.target.value);
                // toggleDisabled();
                break;
            case 'password':
                // changePassword(event.target.value);
                // toggleDisabled();
                break;
            default:
                break;
        }
    }

    async function getBudget() {
        try {
            const userBudget = await httpRequest('GET', `/users/${userData.userId}/budgets`, null);
            updateUserBudget({
                usedBudget: userBudget.usedBudget,
                unusedBudget: userBudget.unusedBudget,
                totalBudget: Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                }).format(userBudget.usedBudget + userBudget.unusedBudget)
            });
            updateUnusedBudget(userBudget.unusedBudget);
        } catch (err) {
            console.log(err);
        }
    }

    async function updateBudget() {
        try {
            let unusedBudgetParse = unusedBudget.replace('R$', '');
            unusedBudgetParse = unusedBudgetParse.replace('.', '');
            unusedBudgetParse = unusedBudgetParse.replace('.', '');
            unusedBudgetParse = unusedBudgetParse.replace('.', '');
            unusedBudgetParse = unusedBudgetParse.replace('.', '');
            unusedBudgetParse = unusedBudgetParse.replace(',', '.');

            await httpRequest('PUT', `/users/${userData.userId}/budgets`, {
                usedBudget: userBudget.usedBudget,
                unusedBudget: parseFloat(unusedBudgetParse)
            });

            toast.info('Aporte atualizado com sucesso')
            // getBudget();
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <section className="">

            <div className="">
                <article className="tile is-child box">
                    <h3>Meu capital: {userBudget.totalBudget}</h3>
                    <br/>
                    <form onSubmit={handleSubmitBudget}>
                        <div className="field is-horizontal">

                            {/*<div className="field-label is-normal">*/}
                            {/*    <label className="label">Aporte</label>*/}
                            {/*</div>*/}
                            <div className="field-body">
                                <div className="field">
                                    <p className="control is-expanded has-icons-left">
                                        <CurrencyInput className="input" value={unusedBudget}
                                                       name="unusedBudget" onFocus={handleFocus}
                                                       onChangeEvent={handleChange}
                                                       decimalSeparator="," thousandSeparator="."
                                                       prefix="R$"
                                                       placeholder="R$123,00"/>

                                        <span className="icon is-small is-left">
                                                      <i className="fas fa-money-bill-alt"/>
                                                    </span>
                                    </p>
                                </div>
                                <div className="control">
                                    <button className="button is-primary" onClick={() => {
                                        updateBudget()
                                    }}>Atualizar
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <p className="help is-success"></p>
                        </div>
                    </form>
                </article>
            </div>
        </section>
    )
}

export default AporteComponent