import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {MainContextProvider} from './contexts/MainContext'
import {BrowserRouter as Router} from 'react-router-dom';
import TagManager from 'react-gtm-module'
const tagManagerArgs = {
    gtmId: 'GTM-PMRVKCSF',
    dataLayer: {
        userId: '001',
        userProject: 'test'
    },
    events: {
        sendUserInfo: 'userInfo'
    }
}

TagManager.initialize(tagManagerArgs)
ReactDOM.render(
    <React.StrictMode>
        <MainContextProvider>
            <Router>
                <App/>
            </Router>
        </MainContextProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
