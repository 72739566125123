import React, {useState, useContext, useEffect} from 'react';
import {MainContext} from '../contexts/MainContext';
import useHttpHook from '../hooks/useHttpHook'
import {useHistory} from 'react-router-dom';
import useLocalStorage from "../hooks/useLocalStorage";
import useLanguage from "../hooks/useLanguageHook";
import LoginComponent from "./home/loginComponent";
import NewUserComponent from "./home/newUserComponent";

function HomeComponent(props) {

    const [selectedPage, setSelectedPage] = useState('home');
    const {languageData} = useLanguage();


    return (
        <section className="hero is-primary is-fullheight">
            <div className="hero-body">
                <div className="container">
                    <div className="columns is-centered">
                        <h1 class="title is-3">{languageData.appTitle}</h1>

                    </div>
                    <div className="columns is-centered">
                        {selectedPage === 'home' ? <LoginComponent/> : ''}
                        {selectedPage === 'newUser' ? <NewUserComponent/> : ''}

                    </div>
                    <div className="columns is-centered">
                        {selectedPage === 'home' ? <a className="button is-warning is-small" onClick={() => {
                            setSelectedPage('newUser')
                        }}><span style={{color: 'black'}}>{languageData.home.newUser}</span></a> : ''
                        }
                        {selectedPage === 'newUser' ? <a className="button is-warning is-small" onClick={() => {
                            setSelectedPage('home')
                        }}><span style={{color: 'black'}}>{languageData.home.login}</span></a> : ''
                        }
                    </div>
                </div>
            </div>
        </section>
    )

}

export default HomeComponent