import React from "react";
import MyAssetsTradingTableComponent from "../components/myAssetsTradingTableComponent";
import AdicionarNegociacaoAtivoComponent from "../components/adicionarNegociacaoAtivoComponent";

function AssetTradingComponent(){
    return(
        <div>
            <div className="container">

                <div className="column is-full">
                    <AdicionarNegociacaoAtivoComponent/>
                </div>
                <div className="column is-full">
                    <section className="info-tiles section-smart-table-flex-center-row">
                        <MyAssetsTradingTableComponent/>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default AssetTradingComponent