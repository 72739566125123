import React from "react";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
const data = [{name: 'Page A', uv: 400, pv: 2400, amt: 2400},
    {name: 'Page A', uv: 300, pv: 2200, amt: 2400},
    {name: 'Page A', uv: 200, pv: 200, amt: 222},
    {name: 'Page A', uv: 100, pv: 2600, amt: 123},
    {name: 'Page A', uv: 300, pv: 7400, amt: 555},
    {name: 'Page A', uv: 400, pv: 2400, amt: 2400},
];

function StocksDashboard(){
    return (<div>
        <div className="in-construction">
            <p>Página em construção <i className="fas fa-pencil-ruler"/></p>
        </div>
        {/*<LineChart width={600} height={300} data={data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>*/}
        {/*    <Line type="monotone" dataKey="uv" stroke="#8884d8" />*/}
        {/*    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />*/}
        {/*    <XAxis dataKey="name" />*/}
        {/*    <YAxis />*/}
        {/*    <Tooltip />*/}
        {/*</LineChart>*/}
    </div>)
}

export default StocksDashboard;