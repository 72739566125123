import React, {useContext, useEffect, useState} from "react";
import CurrencyInput from "react-currency-input";
import useHttpHook from "../hooks/useHttpHook";
import {MainContext} from "../contexts/MainContext";
import SmartTableComponent from "./smartTableComponent";
import useLanguage from "../hooks/useLanguageHook";

function MyAssetsTradingTableComponent() {
    const {httpRequest} = useHttpHook();
    const {userData, LANGUAGE, socket} = useContext(MainContext);
    const [componentLoaded, changeComponentLoaded] = useState(false);
    const [isLoaded, switchLoadade] = useState(false);
    const [assetsData, updateAssets] = useState([]);
    const {languageData} = useLanguage();

    useEffect(() => {
        changeComponentLoaded(true);

    }, []);

    useEffect(() => {
        if (componentLoaded && userData && userData.userId) {
            getAssetsTableSheet();
        }
    }, [userData, componentLoaded, LANGUAGE]);

    function onKeyPress() {

    }

    async function getAssetsTableSheet() {
        // /users/cN3JMHNBeiARvFoukbpc26/resumed-assets
        try {
            const assets = await httpRequest('GET', `/users/${userData.userId}/assets-trading`, null);
            updateAssets(assets);

        } catch (err) {
            console.log(err);
        }
    }

    const settings = {
        noDataMessage: 'Sem ativos no momento',
        columns: {
            dataDoNegocio: {
                title: languageData.myAssetsTradingTable.dataDoNegocio,
                type: 'string',
                editable: false,
                sortType: 'dateFormat',
                sortFormat: 'DD/MM/YYYY'
            },
            stockId: {
                title: languageData.myAssetsTradingTable.stockId,
                type: 'string',
                editable: false,
            },
            compraVenda: {
                title: languageData.myAssetsTradingTable.compraVenda,
                type: 'string',
                editable: false,
            },
            quantidade: {
                title: languageData.myAssetsTradingTable.quantidade,
                type: 'string',
                editable: false,
            },
            preco: {
                title: languageData.myAssetsTradingTable.preco,
                type: 'string',
                editable: false,
                valuePrepareFunction: (value) => {
                    return new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(value);
                },
            },
            corretagem: {
                title: languageData.myAssetsTradingTable.corretagem,
                type: 'string',
                editable: false,
                valuePrepareFunction: (value) => {
                    return new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(value);
                },
            },
            valorTotal: {
                title: languageData.myAssetsTradingTable.valorTotal,
                type: 'string',
                editable: false,
                valuePrepareFunction: (value) => {
                    return new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(value);
                },
            },
            corretoraId: {
                title: languageData.myAssetsTradingTable.corretoraId,
                type: 'string',
                editable: false,
                valuePrepareFunction: (value) => {

                    switch (value) {
                        case '308':
                            return 'Clear';
                        case '90':
                            return 'Easynvest';
                        case '1099':
                            return 'Inter';
                        case '386':
                            return 'Rico';
                        case '1982':
                            return 'Modal';
                        default:
                            return value;
                    }
                },
            },
            mercado: {
                title: languageData.myAssetsTradingTable.mercado,
                type: 'string',
                editable: false,
            },
            // prazoVencimento: {
            //     title: languageData.myAssetsTradingTable.prazoVencimento,
            //     type: 'string',
            //     editable: false,
            // },
            especificacaoAtivo: {
                title: languageData.myAssetsTradingTable.especificacaoAtivo,
                type: 'string',
                editable: false,
            },
            fatorCotacao: {
                title: languageData.myAssetsTradingTable.fatorCotacao,
                type: 'string',
                editable: false,
            },
            tipoNegociacao: {
                title: languageData.myAssetsTradingTable.tipoNegociacao,
                type: 'string',
                editable: false,
                valuePrepareFunction: (value) => {
                    if(value === 'auto_import') return 'CEI';
                    return value;
                },
            }
        },
    };
    return (
        <SmartTableComponent settings={settings} data={assetsData} reloadFunction={getAssetsTableSheet}
                             rowUpdateFunction={onKeyPress}/>
    )
}

export default MyAssetsTradingTableComponent