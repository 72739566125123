import React, {useContext, useEffect, useState} from "react";
import CurrencyInput from "react-currency-input";
import useHttpHook from "../hooks/useHttpHook";
import {MainContext} from "../contexts/MainContext";
import SmartTableComponent from "./smartTableComponent";
import useLanguage from "../hooks/useLanguageHook";
import { toast } from 'react-toastify';

function MyFinantialTableComponent() {
    const {httpRequest} = useHttpHook();
    const {userData, LANGUAGE, socket, setUserStocks} = useContext(MainContext);
    const [componentLoaded, changeComponentLoaded] = useState(false);
    const [isLoaded, switchLoadade] = useState(false);
    const [sheetData, updateSheet] = useState([]);
    const {languageData} = useLanguage();

    useEffect(() => {
        changeComponentLoaded(true);

    }, []);


    useEffect(() => {
        if (componentLoaded && userData && userData.userId) {
            getFinantialTableSheet();
            socket.on(userData.userId, (msg) => {
                if (msg && msg.messageId && (msg.messageId === 'ceiImportProcessed' || msg.messageId === 'needRefreshStocks')) {
                    console.log('NIIICE');
                    getFinantialTableSheet();
                }
                console.log(msg);
            });
        }
    }, [userData, componentLoaded, LANGUAGE]);

    async function keyPress(event) {
        if (event.charCode === 13) {
            event.preventDefault();

            let rowValues = event.currentTarget  // this is the element that has the click listener
                .parentNode  // this would be .actions div
            // .innerHTML;

            let tds = rowValues.getElementsByTagName("td")
            let stockId = tds[0].firstChild.data;
            let score = tds[2].firstChild.data;
            let quantity = tds[3].firstChild.data;
            console.log(stockId, score, quantity);
            const updatedStock = await httpRequest('PUT', `/users/${userData.userId}/stocks/${stockId}`, {
                score: score,
                quantity: quantity
            });
            toast.info('Ativo atualizado com sucesso')
            getFinantialTableSheet();
        }
    }


    async function removeStockFromUser(stockId){
        try {
            const response = await httpRequest('DELETE', `/users/${userData.userId}/stocks/${stockId}`, null);
            toast.info('Aporte removido com sucesso');
            console.log('Aporte removido com sucesso');
        }catch(err){
            console.log(err);
        }
    }

    async function getFinantialTableSheet() {
        // /users/cN3JMHNBeiARvFoukbpc26/resumed-sheet
        try {
            const sheet = await httpRequest('GET', `/users/${userData.userId}/resumed-sheet`, null);
            updateSheet(sheet);
            setUserStocks(sheet);
            /**
             * baseColor: "#E6302E"
             haveAmount: 6864
             havePercentage: 0.0628945282401838
             id: "IVVB11"
             idealAmount: 7179.940131578946
             idealPercentage: 0.06578947368421052
             missingAmount: 315.9401315789464
             price: 176
             quantity: 39
             score: 10
             stockType: "etf"
             suggestedAction: "Comprar"
             suggestedQuantityToBuy: 1
             */
        } catch (err) {
            console.log(err);
        }
    }

    const settings = {
        noDataMessage: 'Sem negociações no momento',
        columns: {
            stockId: {
                title: languageData.myDistributionTable.stockId,
                type: 'string',
                editable: false,
            },
            price: {
                title: languageData.myDistributionTable.price,
                type: 'string',
                editable: false,
                sortType: 'number',
                valuePrepareFunction: (value) => {
                    return new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(value);
                },
            },
            score: {
                title: languageData.myDistributionTable.score,
                type: 'string',
                sortType: 'number',
                isEditable: true
            },
            quantity: {
                title: languageData.myDistributionTable.quantity,
                type: 'string',
                sortType: 'number',
                isEditable: true
            },
            idealPercentage: {
                title: languageData.myDistributionTable.idealPercentage,
                type: 'string',
                editable: false,
                sortType: 'number',
                valuePrepareFunction: (value) => {
                    return ((value) * 100).toFixed(2) + '%';
                },
            },
            havePercentage: {
                title: languageData.myDistributionTable.havePercentage,
                type: 'string',
                editable: false,
                sortType: 'number',
                valuePrepareFunction: (value) => {
                    return ((value) * 100).toFixed(2) + '%';
                },
            },
            suggestedQuantityToBuy: {
                title: languageData.myDistributionTable.missing,
                type: 'string',
                editable: false,
                sortType: 'number',
                valuePrepareFunction: (value) => {
                    value = Number.parseFloat(value.toFixed(4));
                    if (value < 0) {
                        value = 0;
                    }
                    return Number.parseFloat(value);
                },
            },
            suggestedAction: {
                title: languageData.myDistributionTable.suggestedAction.name,
                type: 'string',
                editable: false,
                valuePrepareFunction: (value) => {
                    if (value === 'Comprar') {
                        return languageData.myDistributionTable.suggestedAction.buy
                    } else {
                        return languageData.myDistributionTable.suggestedAction.wait
                    }
                }
            },
            actions: {
                type: 'delete',
                title: "Açōes",
                valuePrepareFunction: async (value) => {
                    console.log(value);
                    await removeStockFromUser(value);
                }
            }
        },
    };
    return (
        <SmartTableComponent settings={settings} data={sheetData} reloadFunction={getFinantialTableSheet}
                             rowUpdateFunction={keyPress}/>
    )
}

export default MyFinantialTableComponent
