import React from "react";
import './InfoCard.css';

function InfoCard(props){

    return (
        <div className={'tile is-parent'}>
            <article className={`tile is-child box tile__hover ${props.data.stockTypeId === props.data.activeStock ? 'isActive' : ''}`}
            onClick={()=>{props.updateActiveFunction(props.data.stockTypeId)}}
            >
                <p className="title td-small-title">{props.data.value}</p>
                <p className="subtitle td-small-subtitle">{props.data.title}</p>
            </article>
        </div>
    )
}

export default InfoCard