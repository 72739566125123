import React from 'react';
import useGameHook from '../hooks/useGameHook';


function GameTypingComponent(props) {
    const {start, updateText, typedText, timeRemaining, wordCount, isRunning, textRef} = useGameHook(5);

    return (
        <div>
            <h1>How fast do you type?</h1>
            <textarea
                ref={textRef}
                value={typedText}
                onChange={updateText}
                disabled={!isRunning}
            />
            <h4>Time remaining: {timeRemaining}</h4>
            <button
                onClick={start}
                disabled={isRunning}
            >Start
            </button>
            <h1>Word count: {wordCount}</h1>
        </div>
    )
}

export default GameTypingComponent