import React, {useContext} from "react";
import useLanguage from "../hooks/useLanguageHook";
import {MainContext} from "../contexts/MainContext";
import {useHistory} from "react-router-dom";

function NavBarNotLoggedComponent(props) {
    const {languageData} = useLanguage();
    const {LANGUAGE, changeLanguage} = useContext(MainContext);
    const history = useHistory();



    return (
        <div>
            <nav className="navbar" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <a role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false"
                       data-target="navbarBasePage">
                        <span aria-hidden="true"/>
                        <span aria-hidden="true"/>
                        <span aria-hidden="true"/>
                    </a>
                </div>

                <div id="navbarBasePage" className="navbar-menu">
                    <div className="navbar-end">
                        <div className="navbar-item ">
                            <div className="buttons">
                                <div className="navbar-item has-dropdown is-hoverable ">
                                    <a className="navbar-link is-arrowless">
                                        {LANGUAGE === 'pt-BR' ? <span className="flag-icon flag-icon-br"/> :
                                            <span className="flag-icon flag-icon-us"/>}
                                    </a>
                                    <div className="navbar-dropdown">
                                        <a className="navbar-item" onClick={()=>{changeLanguage('pt-BR')}}>
                                            <span className="flag-icon flag-icon-br"/>
                                        </a>

                                        <a className="navbar-item" onClick={()=>{changeLanguage('en-US')}}>
                                            <span className="flag-icon flag-icon-us"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            {props.children}
        </div>
    )
}

export default NavBarNotLoggedComponent