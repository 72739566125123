import React, {useContext, useEffect, useState} from "react";
import CurrencyInput from "react-currency-input";
import useHttpHook from "../hooks/useHttpHook";
import {MainContext} from "../contexts/MainContext";
import * as moment from 'moment';
import { toast } from 'react-toastify';

function ImportCeiComponent() {
    const {httpRequest} = useHttpHook();
    const {userData, LANGUAGE, socket} = useContext(MainContext);
    const [componentLoaded, changeComponentLoaded] = useState(false);
    const [isLoaded, switchLoadaded] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [ceiImportStatus, updateCeiImportStatus] = useState('');
    const [lastCei, updatedLastCei] = useState({status: '', updatedAt: ''});
    useEffect(() => {
        changeComponentLoaded(true);

    }, []);

    useEffect(() => {
        if (componentLoaded && userData && userData.userId) {
            socket.on(userData.userId, (msg) => {
                if (msg && msg.messageId && msg.messageId === 'ceiImportProcessed') {
                    updateCeiImportStatus('CEI importado com sucesso!')
                    toast.success('CEI importado com sucesso!');
                }
                console.log(msg);
                httpRequest('GET', `/users/${userData.userId}/cei-import`, null)
                    .then((response) => {
                        console.log(response);
                        updatedLastCei(response);
                    })
            });

            httpRequest('GET', `/users/${userData.userId}/cei-import`, null)
                .then((response) => {
                    console.log(response);
                    updatedLastCei(response);
                });
        }
    }, [userData, componentLoaded, LANGUAGE]);

    const handleFocus = (event) => event.target.select();


    function handleChange(event) {
        switch (event.target.name) {
            case 'username':
                setUsername(event.target.value);

                // toggleDisabled();
                break;
            case 'password':
                setPassword(event.target.value);
                // toggleDisabled();
                break;
            default:
                break;
        }
    }


    function handleSubmit(event) {
        event.preventDefault();
        importCei();
    }

    async function importCei() {
        try {

            await httpRequest('POST', `/users/${userData.userId}/cei-import`, {
                username: username,
                password: password
            });
            updateCeiImportStatus('Estamos importando seus dados do CEI, aguarde');
            setUsername('');
            setPassword('');
            // getBudget();
        } catch (err) {
            console.log(err);
        }
    }
    async function refreshCei() {
        try {

            await httpRequest('PUT', `/users/${userData.userId}/cei-import/refresh`, {});
            updateCeiImportStatus('Estamos importando seus dados do CEI, aguarde');
            setUsername('');
            setPassword('');
            // getBudget();
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <section>
            <div>
                <article className="tile is-child box">

                    <form>
                        <div className="level">
                            <p className="level-left"><strong>Importar via CEI </strong> </p>
                            {lastCei.status !== '' ? <span className="level-right">
                                <span className="help is-info">{lastCei.status === 'processed' ? 'Atualizado em: ' + moment(lastCei.updatedAt).format('DD/MM/YYYY HH:mm') : ''} </span>
                                <span className="" style={{"paddingLeft":"0.3em"}} onClick={()=>{refreshCei()}}><i className="fas fa-sync " style={{cursor: "pointer"}}/></span>
                            </span> : ''}
                        </div>
                        <div className="field is-horizontal">
                            <div className="field-body">
                                <div className="field">
                                    <p className="control is-expanded has-icons-left">
                                        <input type="text" value={username} name="username"
                                               onChange={handleChange} placeholder='username'
                                               className="input" required/>
                                        <span className="icon is-small is-left">
                                                      <i className="fas fa-file-contract"/>
                                                    </span>
                                    </p>
                                </div>
                                <div className="field">
                                    <p className="control is-expanded has-icons-left">
                                        <input type="password" value={password} name="password"
                                               onChange={handleChange} placeholder='password'
                                               className="input" required/>
                                        <span className="icon is-small is-left">
                                                      <i className="fas fa-layer-group"/>
                                                    </span>
                                    </p>
                                </div>
                                <div className="control">
                                    <button className="button is-primary" onClick={handleSubmit}>Importar</button>
                                </div>
                            </div>
                        </div>

                        <div className="field">
                            <p className="help is-success">{ceiImportStatus}</p>
                        </div>

                    </form>
                </article>
            </div>
        </section>
    )
}

export default ImportCeiComponent